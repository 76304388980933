import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../PageContainer";
import ActionCreator from "../ActionCreator";
import { HeaderFontCss, ParagraphFontCss } from "../Utils/CssStyleTemplateUtil";
import * as Widget from "../Components/Widget";

const queryString = require("query-string");

class HistoryDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      article: null,
    };
  }

  async componentDidMount() {
    let { location, appActions } = this.props;
    const parsed = queryString.parse(location.search);

    let article = await appActions.getArticleById(parsed.id);
    this.setState({ article, loading: false });
  }

  render() {
    let { loading, article } = this.state;

    return (
      <Wrapper>
        <div className="introduction-section">
          {loading ? (
            <Widget.Spinner />
          ) : (
            <>
              <h2 className="title">{article.title}</h2>
              <div className="paragraph-container">
                {article.sections
                  .filter(
                    section =>
                      section.type === "title" || section.type === "content"
                  )
                  .map(section => {
                    if (section.type === "title") {
                      return <h3>{section.value}</h3>;
                    }
                    return <p>{section.value}</p>;
                  })}
              </div>
            </>
          )}
        </div>

        <div className="history-image-section">
          {loading ? (
            <Widget.Spinner />
          ) : (
            <>
              <div className="history-image-container">
                <img className="history-image" src={article.image} />
                {article.sections
                  .filter(section => section.type === "image")
                  .map(image => (
                    <img className="history-image" src={image.value} />
                  ))}
              </div>
            </>
          )}
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: relative;
  display: flex;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  .introduction-section {
    background-color: #f1f1f1;
    padding: 30px;
    color: #4e4e4e;
    display: flex;
    flex-direction: column;
    flex-basis: 360px;
    flex-grow: 0;
    flex-shrink: 0;

    & > h2 {
      text-align: left;
      font-size: 26px;
      ${HeaderFontCss}
      margin-bottom: 10px;
    }

    .paragraph-container {
      margin-bottom: 15px;

      & > h3 {
        margin-bottom: 15px;
        font-size: 20px;
        ${HeaderFontCss}
      }

      & > p {
        margin-bottom: 15px;
        font-size: 14px;
        ${ParagraphFontCss}
        letter-spacing: 0.4px;
        white-space: pre-wrap;
        text-align: justify;
        line-height: 20px;
      }
    }
  }

  .history-image-section {
    flex-grow: 1;

    & .history-image-container {
      height: 100%;

      & .history-image {
        width: 100%;
        cursor: pointer;
        height: auto;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .introduction-section {
      /* turn off inner scroll */
      height: auto;
      flex-basis: initial;
      flex-grow: 1;
    }

    & > h2 {
      text-align: left;
    }

    & > p {
      text-align: left;
    }

    .history-image-section {
      /* turn off inner scroll */
      height: auto;
    }
  }

  @media screen and (max-width: 500px) {
    .introduction-section {
      padding: 45px 15px 50px;
    }

    .project-image-section {
      & .project-items-conteainer {
        & .project-item {
          flex-basis: 100%;
        }
      }
    }
  }
`;

export default withPage(connect(null, ActionCreator)(HistoryDetailPage));
